(function () {
  'use strict';

  class DashboardIndexCtrl {
    constructor($state, User, Groups, $scope, $rootScope, ModuleSet) {
      let vm = this;
      vm.$scope = $scope;
      vm.$state = $state;
      vm.User = User;
      vm.Groups = Groups;
      vm.ModuleSet = ModuleSet;
      vm.user = $rootScope.user;
      vm.getModulesSet();
    }
    start(module) {
      let vm = this;
      vm.$state.go('module', {moduleId: module.id});
    }
    selectModuleSet() {
      let vm = this;
      vm.ModuleSet.moduleDocuments({id: vm.moduleSetId})
        .then((data) => {
          vm.modules = data.module_documents;
        });
    }
    getModulesSet() {
      let vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data)=> {
          vm.moduleSets = data.module_sets;
          if (vm.moduleSets.length > 0) {
            vm.moduleSetId = vm.moduleSets[0].id;
            vm.selectModuleSet();
          }
        });
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.index.controller:IndexCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.index')
    .controller('DashboardIndexCtrl', DashboardIndexCtrl);
}());
